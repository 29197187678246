import React from "react"
import styled from "styled-components"
import { ThemeProvider } from "styled-components"
import ThemeContext from "../context/ThemeContext"

const lightTheme = {
  fg: "black",
  bg: "white",
  maxWidth: "1000px",
  headerBg: "rgba(255, 255, 255, 95)",
  accent: "#0B84FF",
  line: "1px solid #E4E4E4",
  reviewCardBg: "#F0F0F8",
  link: "black",
  gradientBright: "linear-gradient(286deg, #3374f6, #00d3ed)",
  gradientSubtle: "linear-gradient(104deg, #050a27 0%, #4a548c 100%)",
  globalTransition: "0.4s cubic-bezier(0.3, 0.8, 0.2, 1)",
}

const darkTheme = {
  fg: "#E7E7E8",
  bg: "#19191F",
  maxWidth: "1000px",
  headerBg: "rgba(19, 20, 21, 85)",
  accent: "#0B84FF",
  line: "1px solid #303035",
  reviewCardBg: "#26272D",
  link: "white",
  gradientBright: "linear-gradient(286deg, #3374f6, #00d3ed)",
  gradientSubtle: "linear-gradient(104deg, #ABB3BB 0%, #D9E4F0 100%)",
  globalTransition: "0.4s cubic-bezier(0.3, 0.8, 0.2, 1)",
}

const Theme = ({ children }) => (
  <ThemeContext.Consumer>
    {theme => (
      <ThemeProvider theme={theme.dark ? darkTheme : lightTheme}>
        <main>{children}</main>
      </ThemeProvider>
    )}
  </ThemeContext.Consumer>
)

export default Theme
