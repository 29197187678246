import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const Footer = () => (
  <FooterStyles>
    <div className="container">
      <a href="https://www.jacobruiz.design/" className="tagline">
        Made on Earth.
      </a>
      <Link to="/releases" className="link">
        Releases
      </Link>
      <Link to="/help" className="link">
        Help
      </Link>
      <span className="twitter">
        👋Say hi!{" "}
        <a href="https://twitter.com/JacobRuizDesign">@JacobRuizDesign</a>
      </span>
    </div>
  </FooterStyles>
)

export default Footer

const FooterStyles = styled.footer`
  border-top: ${props => props.theme.line};
  .container {
    padding: 20px;
    text-align: left;
    letter-spacing: -0.3px;
    color: #b3b3bc;
    font-size: 18px;
    max-width: 1040px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: auto auto auto 1fr;
    grid-gap: 30px;
    .tagline {
      font-weight: bold;
    }
    .link {
      opacity: 0.7;
      transition: 0.2s;
      :hover {
        opacity: 1;
      }
    }
    .twitter {
      color: ${props => props.theme.fg};
      text-align: right;
      a {
        text-decoration: underline;
      }
    }
    @media (max-width: 600px) {
      grid-template-columns: 1fr;
      grid-gap: 15px;
      .twitter {
        text-align: left;
      }
    }
  }
`
