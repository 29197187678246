import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import logo from "../images/logo-mark.svg"
import ThemeContext from "../context/ThemeContext"
import DarkModeToggle from "./DarkModeToggle"

const APP_STORE_LINK =
  "https://itunes.apple.com/us/app/shotty/id1250306151?mt=12"

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hasScrolled: false,
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  handleScroll = event => {
    const scrollTop = window.pageYOffset

    if (scrollTop > 50) {
      this.setState({ hasScrolled: true })
    } else {
      this.setState({ hasScrolled: false })
    }
  }

  render() {
    return (
      <ThemeContext.Consumer>
        {theme => (
          <HeaderStyles>
            <div
              className={
                this.state.hasScrolled ? "Header HeaderScrolled" : "Header"
              }
            >
              <div className="HeaderGroup">
                <Link className="LogoGroup" to="/">
                  <img className="LogoMark" src={logo} alt="logo" />
                  <span className="LogoText">Shotty</span>
                </Link>
                <div className="DarkModeGroup">
                  <DarkModeToggle
                    dark={theme.dark}
                    toggleDark={theme.toggleDark}
                  />
                </div>
                <a href={APP_STORE_LINK} className="ButtonGroup">
                  <button>Download</button>
                </a>
              </div>
            </div>
          </HeaderStyles>
        )}
      </ThemeContext.Consumer>
    )
  }
}

const HeaderStyles = styled.div`
  .Header {
    box-sizing: border-box;
    position: fixed;
    width: 100%;
    padding: 40px 20px;
    z-index: 100;
    transition: 0.8s cubic-bezier(0.3, 0.8, 0.2, 1);
  }

  @media (max-width: 640px) {
    .Header {
      padding: 20px;
    }
  }

  .Header .LogoGroup {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    grid-gap: 10px;
    justify-self: start;
  }

  .Header .LogoGroup .LogoText {
    font-size: 24px;
    font-weight: 800;
    letter-spacing: -0.78px;
  }

  .HeaderScrolled {
    background: ${props => props.theme.bg};
    padding: 12px 20px;
    backdrop-filter: blur(20px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  }

  .Header .LogoMark {
    width: 60px;
    height: 60px;
    transition: 0.8s cubic-bezier(0.3, 0.8, 0.2, 1);
  }

  .HeaderScrolled .LogoMark {
    width: 40px;
    height: 40px;
  }

  .HeaderGroup {
    display: grid;
    margin: 0 auto;
    grid-template-columns: 1fr auto auto;
    grid-gap: 20px;
    align-items: center;
    max-width: ${props => props.theme.maxWidth};
  }

  .Header .ButtonGroup {
    justify-self: end;
  }

  .Header button {
    padding: 10px 20px;
    background-image: linear-gradient(286deg, #3374f6, #00d3ed);
    background-size: cover;
    font-size: 18px;
    color: white;
    font-weight: 700;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
  }

  .Header button:hover {
    transform: translateY(-3px);
  }

  .Header .LogoGroup {
    transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
  }

  .Header .LogoGroup:hover {
    transform: translateY(-3px);
  }
`

export default Header
