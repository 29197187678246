import React from "react"
import styled from "styled-components"
import sun from "../images/sun.svg"
import moon from "../images/moon.svg"

const DarkModeToggle = ({ dark, toggleDark }) => {
  const sunClasses = dark ? "icon visible" : "icon invisible"
  const moonClasses = dark ? "icon invisible" : "icon visible"
  return (
    <Toggle onClick={toggleDark}>
      <img className={sunClasses} src={sun} />
      <img className={moonClasses} src={moon} />
    </Toggle>
  )
}

export default DarkModeToggle

const Toggle = styled.div`
  position: relative;
  cursor: pointer;
  width: 28px;
  height: 28px;

  .icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.3s cubic-bezier(0.2, 0.8, 0.2, 1),
      transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.2);
  }
  .visible {
    opacity: 1;
    transform: rotate(360deg);
  }
  .invisible {
    opacity: 0;
  }
`
