import React from "react"
import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    margin: 0;
    padding: 0;
    background: ${props => props.theme.bg};
    color: ${props => props.theme.fg};
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 22px;
    -webkit-font-smoothing: antialiased;
    line-height: 1.3;
    text-align: center;
    transition: ${props => props.theme.globalTransition};

    a {
      text-decoration: none;
      color: ${props => props.theme.link}
    }

    .gradient-text-bright {
      background: ${props => props.theme.gradientBright};
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .gradient-text-subtle {
      background: ${props => props.theme.gradientSubtle};
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    h1 {
      font-size: 60px;
      line-height: 1.2;
      font-weight: bold;
      margin: 10px 0;
    }
    h2 {
      font-size: 50px;
      line-height: 1.3;
      font-weight: bold;
      margin: 10px 0;
    }
    h3 {
      font-size: 40px;
      line-height: normal;
      font-weight: bold;
      margin: 10px 0;
    }
    h4 {
      font-size: 30px;
      line-height: 1.33;
      font-weight: bold;
      margin: 10px 0;
    }
    h5 {
      font-size: 30px;
      line-height: 1.55;
      font-weight: normal;
      margin: 10px 0;
    }
    h6 {
      font-size: 15px;
      font-weight: 900;
      letter-spacing: 3.4px;
      color: ${props => props.theme.accent};
      margin: 10px 0;
    }
    p {
      font-size: 22px;
      line-height: 1.64;
      font-weight: normal;
      margin: 10px 0;
    }

    @media (max-width: 600px) {
      h1 {
        font-size: 40px;
        line-height: 1.25;
        font-weight: bold;
        margin: 10px 0;
      }
      h2 {
        font-size: 30px;
        line-height: 1.33;
        font-weight: bold;
        margin: 10px 0;
      }
      h3 {
        font-size: 25px;
        line-height: 1.2;
        font-weight: bold;
        margin: 10px 0;
      }
      h4 {
        font-size: 20px;
        line-height: 1.5;
        font-weight: bold;
        margin: 10px 0;
      }
      h5 {
        font-size: 20px;
        line-height: 1.5;
        font-weight: normal;
        margin: 10px 0;
      }
      h6 {
        font-size: 15px;
        font-weight: 900;
        letter-spacing: 3.4px;
        color: ${props => props.theme.accent};
        margin: 10px 0;
      }
      p {
        font-size: 22px;
        line-height: 1.64;
        font-weight: normal;
        margin: 10px 0;
      }
    }

  }
`
export default GlobalStyle
